import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import "./BlogSearch.css";

const BlogSearch = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = () => {
    if (searchTerm && searchTerm.length > 3) {
      navigate(`/blog/search?query=${encodeURIComponent(searchTerm)}`);
      if (onSearch) {
        onSearch(searchTerm);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  // A separate effect to handle immediate search when the component first loads
  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <div className="search-box">
      <input
        type="text"
        placeholder="Search blog..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyPress={handleKeyPress}
      />
      <button onClick={handleSearch} className="search-button">
        <FontAwesomeIcon
          icon={faSearch}
          style={{ color: "#000", fontSize: 20, marginRight: 10 }}
        />
      </button>
    </div>
  );
};

export default BlogSearch;
